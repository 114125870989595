import { Person, Product } from '@/app/models/person';
import {
  usePersonProductsRolesByEmailProductQuery,
  useUpdatePersonProductRoleMutation,
} from '@/app/services/personProductRolesApi';
import { Button, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type AddPermissionsProps = {
  product?: Product | null;
  closeDrawer: CallbackFunction;
  incrementLastTimestamp: CallbackFunction;
  person?: Person | null;
};

type EditableRoles = {
  [key: string]: boolean;
};

type EditableProductRoles = {
  name?: string;
  code?: string;
  roles: EditableRoles;
};

type CallbackFunction = () => void;

export default function CreateResourceSharing(props: AddPermissionsProps) {
  const { t } = useTranslation('resourceSharing');
  const { product, closeDrawer, incrementLastTimestamp, person } = props;

  const { data: personProductRoles } = usePersonProductsRolesByEmailProductQuery({
    productCode: product?.code,
    email: person?.email,
  });

  const [productRoles, setProductRoles] = useState({
    name: product?.name,
    code: product?.code,
    roles: product?.roles.reduce(
      (a, v) => ({
        ...a,
        [v]: product && personProductRoles ? !!personProductRoles?.roles.find((r) => r === v) : false,
      }),
      {},
    ),
  } as EditableProductRoles);

  const setChecked = (role: string) => {
    if (productRoles && productRoles.roles) productRoles.roles[role] = !productRoles.roles[role];
    setProductRoles({ ...productRoles });
  };

  const [personProductRoleSaveAPI] = useUpdatePersonProductRoleMutation();

  useEffect(() => {
    setProductRoles({
      name: product?.name,
      code: product?.code,
      roles: product?.roles.reduce(
        (a, v) => ({
          ...a,
          [v]: product && personProductRoles ? !!personProductRoles?.roles.find((r) => r === v) : false,
        }),
        {},
      ),
    } as EditableProductRoles);
  }, [personProductRoles, product]);

  const savePersonProductRole = async () => {
    const response = await personProductRoleSaveAPI({
      email: person?.email || '',
      code: productRoles.code,
      roles: Object.keys(productRoles?.roles).filter((r) => productRoles?.roles[r]),
    });
    incrementLastTimestamp();
    if ('data' in response) {
      toast.success(
        t('Permissões atualizadas com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
    closeDrawer();
  };
  return (
    <div
      style={{
        width: '480px',
        display: 'flex',
        flexDirection: 'column',
        margin: '24px',
        height: 'calc(100vh - 48px)',
        maxHeight: 'calc(100vh - 48px)',
        flexGrow: 'inherit',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 'inherit' }}>
        <div style={{ color: '#4B4B4B', fontSize: '18px', fontWeight: 'bold', marginBottom: '8px' }}>
          {t('Permissões')}
        </div>
        <div style={{ marginTop: '30px' }}>{person?.email}</div>
        <div style={{ marginTop: '10px' }}>{person?.name}</div>
        <div style={{ marginTop: '30px' }}>{t('Perfis Disponíveis')}</div>
        <div>
          {Object.keys(productRoles.roles || []).map((role) => (
            <div key={role} style={{ display: 'flex' }}>
              <Checkbox
                color="secondary"
                checked={productRoles?.roles && productRoles?.roles[role]}
                onChange={() => {
                  setChecked(role);
                }}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>{role}</div>
            </div>
          ))}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button variant="secondary" onClick={() => closeDrawer()}>
          {t('common:reopen')}
        </Button>
        <Button
          variant="primary"
          onClick={() => savePersonProductRole()}
          style={{
            marginLeft: '16px',
          }}
        >
          {t('send')}
        </Button>
      </div>
    </div>
  );
}
