import { ClosePeriodRequest, NewPeriodRequest, PossibleStatuses, UpdatePeriodRequest } from '@/app/models/period';
import {
  useClosePeriodMutation,
  useCreatePeriodMutation,
  useCurrentPeriodQuery,
  useUpdatePeriodMutation,
} from '@/app/services/periodApi';
import { useGeneratePeriodFilesMutation } from '@/app/services/periodFileApi';
import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getColor, PercentageBar } from '@/components/status/status';
import { SharingResource } from '@app/models/summary';

export type CostSplit = {
  originCostcenter: string;
  destiniCostCenter: string;
  colaborator?: string;
  document?: string;
  type?: string;
  status?: string;
  percent: number;
};

dayjs.extend(utc);

function Row(props: { sharingResource: SharingResource }) {
  const { sharingResource } = props;

  return (
    <TableRow
      sx={{
        '& > *': { borderBottom: 'unset' },
        backgroundColor: '#F5F5F5',
        minHeight: '40px',
        height: '40px',
        maxHeight: '40px',
      }}
      style={{ fontSize: '13px' }}
    >
      <TableCell
        style={{
          padding: '0px 0px 0px 16px',
          width: '33%',
          maxWidth: '33%',
          minWidth: '33%',
          fontSize: '13px',
          position: 'relative',
          overflow: 'hidden',
        }}
        align="left"
        component="td"
      >
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            height: '40px',
            fontSize: '13px',
            top: 0,
            paddingRight: '10px',
            maxWidth: '100%',
            minWidth: '100%',
            lineHeight: '40px',
          }}
        >
          {sharingResource.resource?.name}
        </div>
      </TableCell>
      <TableCell
        align="left"
        style={{ width: '80px', minWidth: '80px', maxWidth: '80px', fontSize: '13px', padding: 0 }}
      >
        {sharingResource.document}
      </TableCell>
      <TableCell
        align="left"
        style={{ width: '80px', minWidth: '80px', maxWidth: '80px', fontSize: '13px', padding: 0 }}
      >
        {sharingResource.type}
      </TableCell>

      <TableCell
        align="left"
        style={{
          width: '33%',
          maxWidth: '33%',
          minWidth: '33%',
          fontSize: '13px',
          position: 'relative',
          overflow: 'hidden',
          padding: 0,
        }}
      >
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            height: '40px',
            fontSize: '13px',
            top: 0,
            paddingRight: '10px',
            maxWidth: '100%',
            lineHeight: '40px',
          }}
        >
          {`${sharingResource.originCostCenter.code} - ${sharingResource.originCostCenter.name}`}
        </div>
      </TableCell>

      <TableCell
        align="left"
        style={{
          width: '33%',
          maxWidth: '33%',
          minWidth: '33%',
          fontSize: '13px',
          position: 'relative',
          overflow: 'hidden',
          padding: 0,
        }}
      >
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            height: '40px',
            fontSize: '13px',
            top: 0,
            paddingRight: '10px',
            maxWidth: '100%',
            lineHeight: '40px',
          }}
        >
          {`${sharingResource.destiniCostCenter.code} - ${sharingResource.destiniCostCenter.name}`}
        </div>
      </TableCell>
      <TableCell align="left" style={{ width: '160px', fontSize: '13px', padding: 0 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <PercentageBar value={sharingResource.percent} color={getColor(sharingResource?.status)} />
          <span style={{ marginLeft: '8px' }}>{`${sharingResource.percent} %`}</span>
        </div>
      </TableCell>
    </TableRow>
  );
}

function ClosePeriodErrorsTable(props: { sharingResources: Array<SharingResource> }) {
  const { t } = useTranslation(['resourceSharing', 'common']);
  const { sharingResources } = props;
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: 'calc(100% - 58px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <Table
          aria-label="collapsible table"
          sx={{
            height: 'max-content',
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                minHeight: '40px',
                height: '40px',
                maxHeight: '40px',
              }}
            >
              <TableCell
                style={{
                  width: '30%',
                  maxWidth: '30%',
                  minWidth: '30%',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: '0px 0px 0px 16px',
                }}
                align="left"
              >
                {`${t('common:collaborator')}`}
              </TableCell>
              <TableCell
                style={{
                  width: '80px',
                  maxWidth: '80px',
                  minWidth: '80px',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                {`${t('document')}`}
              </TableCell>
              <TableCell
                style={{
                  width: '80px',
                  maxWidth: '80px',
                  minWidth: '80px',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                {`${t('contract')}`}
              </TableCell>
              <TableCell
                style={{
                  width: '33%',
                  maxWidth: '33%',
                  minWidth: '33%',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                <div>{`${t('C.C de Origem')}`}</div>
              </TableCell>
              <TableCell
                style={{
                  width: '33%',
                  maxWidth: '33%',
                  minWidth: '33%',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                <div>{`${t('C.C de Destino Encerrado')}`}</div>
              </TableCell>
              <TableCell
                style={{
                  width: '160px',
                  minWidth: '160px',
                  maxWidth: '160px',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                {`${t('percentage')}`}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </div>

      <div style={{ overflow: 'auto', width: '100%' }}>
        <Table style={{ tableLayout: 'fixed', overflow: 'auto', width: '100%' }}>
          <TableBody style={{ overflow: 'auto' }}>
            {sharingResources.map((sharingResource) => (
              <Row
                key={`${sharingResource.document}-${sharingResource.originCostCenter?.code}-${sharingResource.destiniCostCenter?.code}`}
                sharingResource={sharingResource}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  );
}

function PeriodSettings() {
  const { i18n, t } = useTranslation(['period', 'common', 'automaticPeriod']);
  const { data, isSuccess } = useCurrentPeriodQuery();
  const [openingDate, setOpeningDate] = useState<Dayjs | null>(dayjs(data?.initDate));
  const [closingDate, setClosingDate] = useState<Dayjs | null>(dayjs(data?.endDate));
  const [closingHour, setClosingHour] = useState<Dayjs | null>(dayjs(`${data?.endDate}T${data?.endTime}`));
  const [editing, setEditing] = useState(false);
  const [modified, setModified] = useState(false);
  const [saving, setSaving] = useState(false);
  const [creating, setCreating] = useState(false);
  const [reopen, setReOpen] = useState(false);
  const [closePeriodCountt, setClosePeriodCountt] = useState(0);
  const [closePeriodErrors, setClosePeriodErrors] = useState([] as SharingResource[]);

  const [openingOpen, setOpeningOpen] = useState(false);
  const [closingOpen, setClosingOpen] = useState(false);
  const [hourOpen, setHourOpen] = useState(false);

  const [createPeriod] = useCreatePeriodMutation();
  const [updatePeriod] = useUpdatePeriodMutation();
  const [closePeriod] = useClosePeriodMutation();

  const [generatePeriodFile] = useGeneratePeriodFilesMutation();

  useEffect(() => {
    setOpeningDate(dayjs(data?.initDate));
    setClosingDate(dayjs(`${data?.endDate}T${data?.endTime}`));
    setClosingHour(dayjs(`${data?.endDate}T${data?.endTime}`));
  }, [data]);

  async function handleUpdatePeriod() {
    setCreating(false);
    setSaving(true);
    setModified(false);
    setReOpen(false);
    const updatedPeriod = {
      id: data?.id,
      endDate: closingDate?.format('YYYY-MM-DD') as string,
      endTime: closingHour?.format('HH:mm:ss') as string,
    } as UpdatePeriodRequest;

    const response = await updatePeriod(updatedPeriod);
    if ('data' in response) {
      toast.success(
        t('period.saved', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }

    setSaving(false);
    setEditing(false);
  }

  async function handleClosePeriod() {
    const closePeriodData = {
      id: data?.id,
    } as ClosePeriodRequest;

    const response = await closePeriod(closePeriodData);
    if ('data' in response) {
      const closePeriodResponse = response?.data;
      if (closePeriodResponse.sharings.length === 0) {
        toast.success(
          t('Período Fechado!', {
            position: toast.POSITION.TOP_RIGHT,
          }),
        );
      } else {
        setClosePeriodErrors(closePeriodResponse.sharings);
      }
    }
    setClosePeriodCountt(closePeriodCountt + 1);
  }

  async function handleCreatePeriod() {
    setCreating(true);
    setSaving(true);
    setModified(false);
    setReOpen(false);
    const newPeriod = {
      initDate: openingDate?.format('YYYY-MM-DD') as string,
      endDate: closingDate?.format('YYYY-MM-DD') as string,
      endTime: closingHour?.format('HH:mm:ss') as string,
    } as NewPeriodRequest;

    const response = await createPeriod(newPeriod);
    if ('data' in response) {
      toast.success(
        t('period.saved', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }

    setSaving(false);
    setEditing(false);
  }

  function handleStartEdit() {
    setModified(true);
    setEditing(true);
    setOpeningDate(dayjs(data?.initDate));
    setClosingDate(dayjs());
    setClosingHour(dayjs());
  }

  function handleStopEdit() {
    setModified(false);
    setEditing(false);
    setOpeningDate(dayjs(data?.initDate));
    setClosingDate(dayjs(data?.endDate));
    setClosingHour(dayjs(`${data?.endDate}T${data?.endTime}`));
  }

  async function handleGeneratePeriodFiles() {
    toast.info(
      t('creating.files', {
        position: toast.POSITION.TOP_RIGHT,
      }),
    );
    const response = await generatePeriodFile({
      periodId: data?.id as number,
    });
    if ('data' in response) {
      toast.success(
        t('Arquivos de Rateio Criados!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  }

  return (
    <Card
      sx={{
        boxShadow: 3,
        display: 'flex',
        minHeight: '72px',
        alignItems: 'center',
        padding: '24px',
        marginRight: '24px',
      }}
    >
      <CardContent sx={{ alignItems: 'center', padding: '0px' }}>
        <>
          <Dialog open={saving} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {' '}
            <DialogContent>
              <div
                style={{
                  margin: '45px 250px 15px 250px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="secondary" />
              </div>
              <div
                style={{
                  fontSize: '25px',
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '5px',
                }}
              >
                {t(creating ? 'Criando Período' : 'Reabrindo Período')}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '45px',
                }}
              >
                {t('Por favor aguarde...')}
              </div>
            </DialogContent>
          </Dialog>
          <div style={{ display: 'flex', marginBottom: '24px' }}>
            <div style={{ marginRight: '24px' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#4b4b4b', marginBottom: '4px' }}>
                {t('opening')}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                <DatePicker
                  open={openingOpen}
                  onClose={() => setOpeningOpen(false)}
                  slotProps={{ textField: { size: 'small', onClick: () => setOpeningOpen(true) } }}
                  disabled={(data && PossibleStatuses[data.status] === PossibleStatuses.OPENED) || reopen}
                  value={openingDate?.local()}
                  onChange={(newValue) => {
                    if (!editing && data) {
                      handleStartEdit();
                    }

                    setOpeningDate(newValue);
                    setModified(true);
                  }}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginRight: '24px' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#4b4b4b', marginBottom: '4px' }}>
                {t('closing')}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                <DatePicker
                  open={closingOpen}
                  onClose={() => {
                    setClosingOpen(false);
                  }}
                  slotProps={{ textField: { size: 'small', onClick: () => setClosingOpen(true) } }}
                  value={closingDate?.local()}
                  onChange={(newValue) => {
                    if (!editing && data) {
                      handleStartEdit();
                    }
                    setClosingDate(newValue);
                    setModified(true);
                  }}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginRight: '24px' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#4b4b4b', marginBottom: '4px' }}>
                {t('closing.hour')}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                <TimePicker
                  ampm={false}
                  open={hourOpen}
                  onClose={() => {
                    setHourOpen(false);
                  }}
                  slotProps={{ textField: { size: 'small', onClick: () => setHourOpen(true) } }}
                  value={closingHour?.local()}
                  onChange={(newValue) => {
                    if (!editing && data) {
                      handleStartEdit();
                    }
                    setClosingHour(newValue);
                    setModified(true);
                  }}
                />
              </LocalizationProvider>
            </div>
            <Dialog
              open={closePeriodErrors.length > 0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                '& .MuiDialog-container': {
                  '& .MuiPaper-root': {
                    width: '100%',
                    maxWidth: '950px',
                    maxHeight: '450px',
                  },
                },
              }}
            >
              {' '}
              <DialogTitle id="alert-dialog-title">{t('Atenção! Não foi possível fechar o período')}</DialogTitle>
              <DialogContent style={{ height: '330px' }}>
                <DialogContentText id="alert-dialog-description">
                  {t(
                    'Ainda existem colaboradores alocados em Centros de Custo de destino encerrados. Retire-os destes Centros de Custo encerrados ou exclua o rateio antes de tentar fechar o período novamente.',
                  )}
                </DialogContentText>
                <ClosePeriodErrorsTable sharingResources={closePeriodErrors} />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    marginLeft: '16px',
                    height: '32px',
                    color: '#FFFFFF',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    textTransform: 'initial',
                  }}
                  onClick={() => setClosePeriodErrors([])}
                  autoFocus
                >
                  {t('Fechar')}
                </Button>
              </DialogActions>
            </Dialog>
            <div style={{ marginRight: '24px' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#4b4b4b', marginBottom: '4px' }}>
                {t('common:status')}
              </Typography>
              <div style={{ height: '100%' }}>
                {(isSuccess && PossibleStatuses[data.status] === PossibleStatuses.OPENED) ||
                (editing &&
                  (closingDate?.isAfter(dayjs()) ||
                    (closingDate?.isSame(dayjs(), 'day') && closingHour?.isAfter(dayjs().subtract(5, 'minute'))))) ? (
                  <>
                    <Chip
                      sx={{ marginTop: '3px', background: '#C8F8D3', color: '#28A745', maxHeight: '24px' }}
                      label={t('common:open')}
                    />

                    {!editing && data && (
                      <Button
                        variant="text"
                        color="secondary"
                        sx={{ marginTop: '3px', marginLeft: '16px', textTransform: 'none' }}
                        onClick={handleClosePeriod}
                      >
                        {t('Fechar')}
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Chip
                      sx={{ marginTop: '3px', background: '#E4E4E4', color: '#888888', maxHeight: '24px' }}
                      label={t('common:closed')}
                    />
                    {!editing && data && (
                      <Button
                        variant="text"
                        color="secondary"
                        sx={{ marginTop: '3px', marginLeft: '16px', textTransform: 'none' }}
                        onClick={() => {
                          setReOpen(true);
                          handleStartEdit();
                        }}
                      >
                        {t('common:reopen')}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {editing && (
              <Button
                color="primary"
                sx={{ color: '#4B4B4B', backgroundColor: '#F5F5F5', marginRight: '16px' }}
                variant="contained"
                onClick={handleStopEdit}
              >
                {t('common:reopen')}
              </Button>
            )}
            <Button
              color="secondary"
              sx={{ color: '#FFFFFF', marginRight: '16px' }}
              disabled={!modified || saving}
              variant="contained"
              onClick={() => {
                if ((editing && data && PossibleStatuses[data.status] === PossibleStatuses.OPENED) || reopen) {
                  handleUpdatePeriod();
                } else {
                  handleCreatePeriod();
                }
              }}
            >
              {t('common:save')}
            </Button>
            <Button
              disabled={data?.status !== 'CLOSED'}
              variant="primary"
              color="primary"
              onClick={() => {
                handleGeneratePeriodFiles();
              }}
            >
              {t('create.files')}
            </Button>

            <Typography sx={{ fontStyle: 'italic', fontSize: '12px', color: '#888888', marginLeft: '24px' }}>
              {t('automaticPeriod:managers.notification')}
            </Typography>
          </div>
        </>
      </CardContent>
    </Card>
  );
}

export default PeriodSettings;
