import { PaginationResponse } from '@/app/models/pagination';
import { useCostCentersSearchNameQuery } from '@/app/services/costcenterApi';
import { CostCenter } from '@app/models/costcenter';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

function CostcenterBreadcrumb() {
  const { code } = useParams();
  const openedCostcenter = useCostCentersSearchNameQuery({ query: code || '', showClosed: false });
  const closedCostCenter = useCostCentersSearchNameQuery({ query: code || '', showClosed: true });
  const list =
    openedCostcenter?.data?.content && closedCostCenter?.data?.content
      ? [
          ...(openedCostcenter.data as PaginationResponse<CostCenter>).content,
          ...(closedCostCenter.data as PaginationResponse<CostCenter>).content,
        ]
      : [];

  const data = list.length > 0 ? list[0] : null;

  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ marginTop: '32px' }}>
        {code ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{ fontWeight: 'bold', fontSize: 18, color: '#F97C06', cursor: 'pointer', textAlign: 'center' }}
              onClick={() => navigate('/costcenter')}
            >
              {t('common:cost.centers')}
            </Typography>
            <ArrowForwardIosIcon sx={{ color: '#888888', height: '8px' }} />
            <Typography sx={{ fontWeight: 'bold', fontSize: 14, color: '#888888', textAlign: 'center' }}>{`${code}${
              data ? ` - ${data.name}` : ''
            }`}</Typography>
          </div>
        ) : (
          <Typography sx={{ fontWeight: 'bold', fontSize: 18, color: '#4b4b4b' }}>
            {t('common:cost.centers')}
          </Typography>
        )}
      </div>
      <Outlet />
    </div>
  );
}
export default CostcenterBreadcrumb;
