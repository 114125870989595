import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const VERSION = process.env.DEPLOY_VERSION || '3.0.2';
const DATE = process.env.DEPLOY_DATE || '18/10/2023';

const ContentWrapperCentered = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  flexBasis: 'auto',
  height: 'calc(100vh - 40px)',
  width: '450px',
  margin: '0 auto',
  boxSizing: 'border-box',
  minWidth: 0,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  color: '#666666',
  lineHeight: 1,
  fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
});

const CesarCard = styled(Box)({
  minWidth: '380px',
  padding: '40px 0',
  boxShadow: '0px 1px 4px #00000029',
  borderRadius: '10px',
  opacity: 1,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'center',
});

const VersionInfo = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '10px',
  width: '370px',
  fontSize: '0.75em',
  color: '#a8a8a8',
});

const Footer = styled(Box)({
  padding: '0 20px',
  boxSizing: 'border-box',
  position: 'absolute',
  bottom: 0,
  width: '100%',
  overflow: 'hidden',
  height: '48px',
});

const FooterInfo = styled(Box)({
  fontSize: '12px',
  color: '#a8a8a8',
  fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
});

function Login() {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const login = useCallback(() => {
    keycloak?.login({ idpHint: process.env.KEYCLOACK_LOGIN_PROVIDER });
  }, [keycloak]);

  if (keycloak?.authenticated) {
    navigate('/');
    return null;
  }

  return (
    <ContentWrapperCentered>
      <CesarCard>
        <img style={{ width: '180px', marginBottom: '40px' }} src="/logo_rateio.svg" alt={`${t('sharing')}`} />
        <div aria-hidden onClick={login} onKeyUp={login} role="button" style={{ cursor: 'pointer' }}>
          <img src="/botao_login.svg" alt={`${t('login.button')}`} />
        </div>
      </CesarCard>
      <VersionInfo>
        <span>
          <span>{`${t('version')}`}</span>
          <span>{VERSION}</span>
          <span>{' - '}</span>
          <span>{DATE}</span>
        </span>
        <span>{`${t('cesar.tools')}`}</span>
      </VersionInfo>
      <p>{`${t('lgpd.info')}`}</p>
      <Footer>
        <FooterInfo>{`${t('version')}${VERSION} © ${DATE.split('/')[2]} CESAR. ${t(
          'all.rights.reserved',
        )}`}</FooterInfo>
      </Footer>
    </ContentWrapperCentered>
  );
}
export default Login;
