import { PossibleStatuses } from '@/app/models/period';
import DownloadIcon from '@/icons/download.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { NotProcessContract } from '@/app/models/contract';
import { PeriodTabs } from '@/app/models/utils/requests';
import { useNotProcessContractQuery, useProcessContractMutation } from '@/app/services/contractApi';
import { useAllByYearQuery } from '@/app/services/periodApi';
import { useGetPeriodFilesByIdQuery } from '@/app/services/periodFileApi';
import { Period } from '@models/period';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ListNotContractGrid from './NotProcessedContract';
import StatusTabs from './StatusTabs';

const FIRST_YEAR = 2019;

type DownloadFilesTooltipProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
  open: boolean;
  files: {
    filePath: string;
    fileName: string;
    fileType: 'CROSS_BRANCH' | 'SHARING' | 'HEADCOUNT' | 'SCHOOL';
  }[];
};

const TypeTranslations = {
  CROSS_BRANCH: 'Planilha de Rateio entre Regionais',
  SHARING: 'Planilha de Rateios',
  HEADCOUNT: 'Planilha de HeadCount',
  SCHOOL: 'Planilha School',
};

const DownloadFilesTooltip = styled(({ children, open, files }: DownloadFilesTooltipProps) => (
  <Tooltip
    arrow
    PopperProps={{
      disablePortal: true,
    }}
    disableFocusListener
    disableHoverListener
    disableTouchListener
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: '#FFFFFF',

          border: '1px solid #D9D9D9',
          '& .MuiTooltip-arrow': {
            color: '#FFFFFF',
            '&:before': {
              border: '1px solid #D9D9D9',
            },
          },
        },
      },
    }}
    open={open}
    placement="left"
    title={
      <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
        {files?.map((file) => (
          <div
            style={{
              width: '250px',
              textAlign: 'start',
            }}
            key={file.fileName}
          >
            <a
              href={file.filePath}
              style={{
                textTransform: 'none',
                textDecoration: 'none',
                display: 'flex',
                margin: '8px',
                marginLeft: '4px',
              }}
            >
              <img src={DownloadIcon} alt="Icone de Download" />
              <Typography sx={{ fontSize: '13px', color: '#666666', marginLeft: '8px' }}>
                {TypeTranslations[file.fileType]}
              </Typography>
            </a>
          </div>
        ))}
        {(!files || files.length === 0) && (
          <div style={{ display: 'flex' }}>
            <span style={{ marginLeft: '8px', color: '#000000' }}>Nenhum arquivo gerado...</span>
          </div>
        )}
      </div>
    }
  >
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    padding: '1px',
  },
}));

function Row(props: { period: Period; index: number }) {
  const { i18n, t } = useTranslation('period');
  const { period, index } = props;
  const [selectedPeriod, setSelectedPeriod] = useState(0 as number);

  const { data: periodFiles } = useGetPeriodFilesByIdQuery({ periodId: selectedPeriod });
  const [openTooltip, setOpenTooltip] = useState(false);

  return (
    <TableRow
      sx={{
        '& > *': { borderBottom: 'unset' },
        backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA',
        minHeight: '40px',
        height: '40px',
        maxHeight: '40px',
      }}
      style={{ fontSize: '13px' }}
    >
      <TableCell
        style={{
          padding: '0px 0px 0px 16px',
          fontSize: '13px',
          position: 'relative',
          overflow: 'hidden',
        }}
        align="left"
        component="td"
      >
        <div>{period.initDate.split('-')[0]}</div>
      </TableCell>
      <TableCell align="left" style={{ fontSize: '13px', padding: 0 }}>
        {DateTime.fromISO(period.initDate, { zone: 'America/Sao_Paulo' }).setLocale(i18n.language).monthLong}
      </TableCell>
      <TableCell align="left" style={{ fontSize: '13px', padding: 0 }}>
        {DateTime.fromISO(period.initDate, { zone: 'America/Sao_Paulo' }).toLocaleString(DateTime.DATE_SHORT, {
          locale: i18n.language,
        })}
      </TableCell>
      <TableCell align="left" style={{ fontSize: '13px', padding: 0 }}>
        {DateTime.fromISO(period.endDate, { zone: 'America/Sao_Paulo' })
          .set({ hour: parseInt(period.endTime.split(':')[0], 10) })
          .set({ minute: parseInt(period.endTime.split(':')[1], 10) })
          .set({ second: parseInt(period.endTime.split(':')[2], 10) })
          .setZone()
          .toLocaleString(DateTime.DATE_SHORT, {
            locale: i18n.language,
          })}
      </TableCell>
      <TableCell align="left" style={{ fontSize: '13px', padding: 0 }}>
        {DateTime.fromFormat(period.endTime, 'HH:mm:ss', { zone: 'America/Sao_Paulo' }).setZone().toFormat('HH:mm:ss')}
      </TableCell>
      <TableCell align="left" style={{ fontSize: '13px', padding: 0 }}>
        {PossibleStatuses[period.status] === PossibleStatuses.OPENED ? t('common:open') : t('common:closed')}
      </TableCell>
      <TableCell align="right" style={{ fontSize: '13px', padding: '0 25px 0 0 ' }}>
        <DownloadFilesTooltip open={openTooltip} files={periodFiles ? periodFiles?.files : []}>
          <IconButton
            onClick={() => {
              if (openTooltip) {
                setOpenTooltip(false);
                setSelectedPeriod(-1);
              } else {
                setOpenTooltip(true);
                setSelectedPeriod(period.id);
              }
            }}
          >
            <MoreVertIcon className="action" />
          </IconButton>
        </DownloadFilesTooltip>
      </TableCell>
    </TableRow>
  );
}

function PeriodTable(props: { periods: Array<Period> }) {
  const { t } = useTranslation(['period', 'common']);
  const { periods } = props;
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <Table
          aria-label="collapsible table"
          sx={{
            height: 'max-content',
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                minHeight: '40px',
                height: '40px',
                maxHeight: '40px',
              }}
            >
              <TableCell
                style={{
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: '0px 0px 0px 16px',
                  width: '90px',
                }}
                align="left"
              >
                {`${t('year')}`}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                  width: '110px',
                }}
                align="left"
              >
                {`${t('month')}`}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                  width: '120px',
                }}
                align="left"
              >
                {`${t('opening')}`}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                  width: '120px',
                }}
                align="left"
              >
                {`${t('closing')}`}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                  width: '200px',
                }}
                align="left"
              >
                {`${t('closing.hour')}`}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                {`${t('common:status')}`}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                  paddingRight: '20px',
                }}
                align="right"
              >
                {`${t('common:actions')}`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ overflow: 'auto' }}>
            {periods.map((period, index) => (
              <Row key={`${period.id}`} period={period} index={index} />
            ))}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  );
}

function HistoricalPeriodList() {
  const { t } = useTranslation('period');
  const currentYear = new Date().getFullYear();
  const yearsList = Array(currentYear - FIRST_YEAR + 1)
    .fill(0)
    .map((_, idx) => FIRST_YEAR + idx)
    .reverse();
  const [year, setYear] = useState(currentYear);
  const { data } = useAllByYearQuery(year);

  const { data: notProcessedContracts } = useNotProcessContractQuery();
  const [tab, setTab] = useState(PeriodTabs.HISTORY);

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const [processContract] = useProcessContractMutation();

  async function handleProcess() {
    const response = await processContract({
      ids: selectedRows.map((i) => parseInt(i as string, 10)),
    });
    if ('data' in response) {
      toast.success(
        t('contracts.processed.successfully', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '40px', marginRight: '24px' }}>
      <Box
        component="div"
        sx={{
          width: '100%',
          height: '100%',
          pr: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          top: 40,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <StatusTabs indexTab={tab} onChangeTab={(newValue: PeriodTabs) => setTab(newValue)} />
          {tab === PeriodTabs.NOTPROCESSCONTRACT && (
            <Button disabled={selectedRows.length <= 0} variant="primary" onClick={() => handleProcess()}>
              {t('process')}
            </Button>
          )}
        </div>
        {tab === PeriodTabs.HISTORY ? (
          <>
            <div style={{ color: '#4B4B4B', fontSize: '14px', marginTop: '16px', fontWeight: 'bold' }}>{t('year')}</div>
            <FormControl>
              <Select
                style={{ width: '200px', height: '32px', fontSize: '13px' }}
                value={year}
                onChange={(e) => {
                  setYear(Number(e.target.value));
                }}
              >
                {yearsList.map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ marginTop: '16px' }}>
              <PeriodTable periods={data || []} />
            </div>
          </>
        ) : (
          <div style={{ width: '100%', height: '500px', display: 'flex', flexDirection: 'column' }}>
            <ListNotContractGrid
              content={notProcessedContracts as NotProcessContract[]}
              rowSelectionModel={selectedRows}
              setRowSelectionModel={setSelectedRows}
            />
          </div>
        )}
      </Box>
    </div>
  );
}

export default HistoricalPeriodList;
