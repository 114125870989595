import { useCostCentersByCodeQuery } from '@/app/services/costcenterApi';
import { useCostCenterResourceQuery } from '@/app/services/costCenterResouceApi';
import {
  useDeleteResourceSharingMutation,
  useSharingByPeriodAndOriginCostCenterQuery,
} from '@/app/services/resourceSharingApi';
import CostCenterDetailsTable, { CostSplit, Employee } from '@/components/costcenter/CostCenterDetailsTable';
import CreateResourceSharing from '@/components/costcenter/CreateResourceSharing';
import SearchField from '@/components/SearchField';
import { CostCenter, PossibleCostCenterStatuses } from '@app/models/costcenter';
import { RootState } from '@app/store';
import styled from '@emotion/styled';
import { Period } from '@models/period';
import { SharingResource } from '@models/summary';
import { Drawer } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

const CostCenterScreen = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

function formatResourceSharing(list: Array<SharingResource>): Array<Employee> {
  const formattedList = list
    .filter((l) => l.base)
    .map((m) => ({
      id: m?.id,
      name: m?.resource?.name,
      email: m?.resource?.email,
      type: m.type,
      document: m.document,
      percent: m.percent,
      costsplits: [] as CostSplit[],
    }));
  for (let i = 0; i < formattedList.length; i += 1) {
    const item = formattedList[i];
    item.costsplits = list
      .filter((s) => !s.base && s?.resource?.email === item.email && s?.document === item.document)
      .map((m) => ({
        id: m?.id,
        destiniCostCenter: m?.destiniCostCenter,
        supportCostCenter: m?.supportCostCenter,
        approverName: m.approverName,
        status: m.status,
        percent: m.percent,
        observation: m.observation,
      }));
  }
  return formattedList;
}

function CostcenterDetails() {
  const [lastTimestamp, setLasTimestamp] = useState(0);
  const [deleteResourceSharingAPI] = useDeleteResourceSharingMutation();
  const periodState = useSelector((state: RootState) => state.splitApi.queries['currentPeriod(undefined)']);
  const { code } = useParams();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation('costcenter');
  const costcenterResponse = useCostCentersByCodeQuery({
    code,
  });
  const costcenterData = costcenterResponse?.data;

  const [searchParams, setSearchParams] = useSearchParams({});
  const value = searchParams.get('name');
  const [searchName, setSearchName] = useState<string | undefined>(value || '');
  const [employee, setEmployee] = useState<Employee | undefined>();
  const [openNewResourceDrawer, setOpenNewResourceDrawer] = useState(false);

  const isPMO = keycloak.hasResourceRole('PMO', process.env.REACT_APP_KEYCLOAK_CLIENT_ID);

  const myCostCenters = useCostCenterResourceQuery({
    query: (costcenterData as CostCenter) ? (costcenterData as CostCenter).code : '-1',
    email: isPMO ? null : keycloak?.tokenParsed?.email,
    bringClosed: (costcenterData as CostCenter)
      ? (costcenterData as CostCenter).status !== PossibleCostCenterStatuses[PossibleCostCenterStatuses.OPEN]
      : false,
    page: 0,
    orderBy: 'name',
    order: 'ASC',
  });

  const { data } = useSharingByPeriodAndOriginCostCenterQuery({
    period: (periodState?.data as Period)?.id || -1,
    costcenterCode: code || '',
    timestamp: lastTimestamp,
  });

  const updateSearchName = (name: string) => {
    setSearchParams({ name });
    setSearchName(name);
  };
  const createNewResourceSharing = (selectedEmployee: Employee) => {
    setEmployee(selectedEmployee);
    setOpenNewResourceDrawer(true);
  };
  const closeDrawer = () => {
    setOpenNewResourceDrawer(false);
  };
  const employees = formatResourceSharing(data || []);

  const deleteResourceSharing = async (id: number) => {
    await deleteResourceSharingAPI({
      id,
    });
    setLasTimestamp(lastTimestamp + 1);
  };

  const incrementLastTimestamp = () => {
    setLasTimestamp(lastTimestamp + 1);
  };

  return (
    <CostCenterScreen>
      <Drawer onBackdropClick={() => setOpenNewResourceDrawer(false)} open={openNewResourceDrawer} anchor="right">
        <CreateResourceSharing
          closeDrawer={closeDrawer}
          periodId={(periodState?.data as Period)?.id}
          employee={employee}
          incrementLastTimestamp={incrementLastTimestamp}
          costcenter={costcenterData as CostCenter}
        />
      </Drawer>
      <SearchField
        style={{ width: '360px', marginTop: '23px', marginBottom: '16px' }}
        searchTerm={searchName}
        setSearchTerm={updateSearchName}
        placeholder={t('type.collaborator.name.badge') as string}
      />
      <CostCenterDetailsTable
        deleteResourceSharing={deleteResourceSharing}
        employees={employees}
        searchName={searchName}
        createNewResourceSharing={createNewResourceSharing}
        incrementLastTimestamp={incrementLastTimestamp}
        periodId={(periodState?.data as Period)?.id}
        canEdit={isPMO || (myCostCenters.data ? myCostCenters.data.content.length > 0 : false)}
        costcenter={costcenterData as CostCenter}
      />
    </CostCenterScreen>
  );
}

export default CostcenterDetails;
