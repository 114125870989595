import { PeriodTabs } from '@/app/models/utils/requests';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

type StatusTabsProps = {
  indexTab: PeriodTabs;
  onChangeTab: (newValue: PeriodTabs) => void;
};

export default function StatusTabs(props: StatusTabsProps) {
  const { indexTab, onChangeTab } = props;
  const { t } = useTranslation('period');

  const CustomBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
      top: '50%',
      width: 25,
      transform: 'translateY(-50%)  translateX(120%)',
    },
  }));

  return (
    <Box sx={{ borderBottom: 1, borderBottomColor: '#DEDEDE' }}>
      <Tabs
        value={indexTab}
        onChange={(event: React.SyntheticEvent, newValue: PeriodTabs) => {
          onChangeTab(newValue);
        }}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="Phone tabs"
      >
        <Tab
          value={PeriodTabs.HISTORY}
          label={<CustomBadge>{t('historical')}</CustomBadge>}
          sx={{ textTransform: 'none', pr: 4, pb: 0 }}
        />
        <Tab
          value={PeriodTabs.NOTPROCESSCONTRACT}
          label={t('not.processed.contracts')}
          sx={{ textTransform: 'none', pb: 0 }}
        />
      </Tabs>
    </Box>
  );
}
