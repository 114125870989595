import { SolicitationStatuses, useSolicitationStatusTranslations } from '@/app/models/utils/requests';
import { Button, Checkbox, FormControlLabel, MenuItem, Select, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import SearchField from '../SearchField';

type RequestsInputProps = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  statusSelect: keyof typeof SolicitationStatuses;
  setStatusSelect: Dispatch<SetStateAction<keyof typeof SolicitationStatuses>>;
  showMine: boolean;
  setShowMine: Dispatch<SetStateAction<boolean>>;
  isPMO: boolean;
  canReject: boolean;
  canApprove: boolean;
  handleApproveRequests: () => void;
  handleRejectRequests: () => void;
};

function RequestsInput(props: RequestsInputProps) {
  const {
    search,
    setSearch,
    statusSelect,
    setStatusSelect,
    showMine,
    setShowMine,
    isPMO,
    canReject,
    canApprove,
    handleApproveRequests,
    handleRejectRequests,
  } = props;
  const { t } = useTranslation(['requestsInput', 'common']);

  const translations = useSolicitationStatusTranslations();

  return (
    <div style={{ display: 'flex', marginTop: '32px', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex' }}>
        <div>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#4b4b4b',
              marginBottom: '4px',
            }}
          >
            {t('common:search')}
          </Typography>

          <SearchField
            searchTerm={search}
            setSearchTerm={setSearch}
            placeholder={t('common:search.by.collaborators.name.or.document') as string}
          />
        </div>
        <div style={{ marginLeft: '24px' }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#4b4b4b',
              marginBottom: '4px',
            }}
          >
            {t('common:status')}
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            sx={{ fontSize: '13px' }}
            id="demo-simple-select"
            value={statusSelect}
            label="Status"
            size="small"
            onChange={(e) => {
              setStatusSelect(e.target.value as keyof typeof SolicitationStatuses);
            }}
          >
            {Object.keys(SolicitationStatuses).map((e) => (
              <MenuItem value={e} key={e} sx={{ fontSize: '13px' }}>
                {translations[SolicitationStatuses[e as keyof typeof SolicitationStatuses]]}
              </MenuItem>
            ))}
          </Select>
        </div>
        {isPMO && (
          <FormControlLabel
            sx={{ whiteSpace: 'nowrap', fontSize: '12px', marginTop: '22px', marginLeft: '24px' }}
            control={
              <Checkbox
                color="secondary"
                checked={showMine}
                onChange={() => {
                  setShowMine(!showMine);
                }}
              />
            }
            label={<Typography sx={{ fontSize: '12px' }}>{t('common:show.only.my.ccs')}</Typography>}
          />
        )}
      </div>
      <div>
        <Button
          disabled={!canReject}
          sx={{ marginTop: '24px', marginLeft: '16px' }}
          variant="secondary"
          onClick={() => handleRejectRequests()}
        >
          {t('common:reject')}
        </Button>

        <Button
          disabled={!canApprove}
          sx={{ marginTop: '24px', marginLeft: '16px' }}
          variant="primary"
          color="primary"
          onClick={() => handleApproveRequests()}
        >
          {t('common:approve')}
        </Button>
      </div>
    </div>
  );
}

export default RequestsInput;
