import HistoricalPeriodList from '@/components/period/HistoricalPeriodList';
import PeriodSettings from '@/components/period/PeriodSettings';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Period() {
  const { t } = useTranslation(['period', 'common']);
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ fontWeight: 'bold', fontSize: '18px', color: '#4b4b4b', marginBottom: '8px' }}>
        {t('common:period')}
      </Typography>
      <Typography sx={{ fontSize: '13px', color: '#888888', marginBottom: '24px' }}>
        {t('configure.the.opening.and.closing.dates.of.the.current.period.at.any.time')}
      </Typography>
      <PeriodSettings />
      <HistoricalPeriodList />
    </div>
  );
}
export default Period;
