import {
  useCurrentPeriodConfigurationQuery,
  useSavePeriodConfigurationMutation,
} from '@/app/services/periodConfiguration';
import AutomaticPeriodSettings from '@/components/automaticPeriod/automaticPeriodSettings';
import { Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function AutomaticPeriod() {
  const { t } = useTranslation('automaticPeriod');

  const { data: currentConfiguration } = useCurrentPeriodConfigurationQuery();
  const [openingDay, setOpeningDay] = useState(currentConfiguration?.openDay || 1);
  const [closingDay, setClosingDay] = useState(currentConfiguration?.closeDay || 30);
  const [closingHour, setClosingHour] = useState<Dayjs | null>(dayjs(`2022-02-22T${currentConfiguration?.closeTime}`));

  const [savePeriodConfioguration] = useSavePeriodConfigurationMutation();

  useEffect(() => {
    setOpeningDay(currentConfiguration?.openDay || 1);
    setClosingDay(currentConfiguration?.closeDay || 30);
    setClosingHour(dayjs(`2022-02-22T${currentConfiguration?.closeTime}`));
  }, [currentConfiguration]);

  async function handleSave() {
    const response = await savePeriodConfioguration({
      openDay: openingDay,
      closeDay: closingDay,
      closeTime: closingHour?.format('HH:mm:ss') as string,
    });
    if ('data' in response) {
      toast.success(
        t('period.config.saved', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography
        sx={{ fontWeight: 'bold', fontSize: '18px', color: '#4b4b4b', marginTop: '32px', marginBottom: '8px' }}
      >
        {t('auto.routine')}
      </Typography>
      <Typography sx={{ fontSize: '13px', color: '#888888', marginBottom: '8px' }}>
        {t('config.dates.auto.routine')}
      </Typography>
      <AutomaticPeriodSettings
        openingDay={openingDay}
        setOpeningDay={setOpeningDay}
        closingDay={closingDay}
        setClosingDay={setClosingDay}
        closingHour={closingHour}
        setClosingHour={setClosingHour}
        handleSave={handleSave}
      />
    </div>
  );
}
export default AutomaticPeriod;
