import { NotProcessContract } from '@/app/models/contract';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

const useColumns = (): GridColDef[] => {
  const { t } = useTranslation(['period', 'common']);

  return [
    {
      field: 'name',
      headerName: t('common:collaborator') as string,
      flex: 1,
      sortable: false,
    },
    { field: 'document', headerName: t('document') as string, sortable: false },
    { field: 'initDate', headerName: t('initial.date') as string, sortable: false },
    {
      field: 'originCostCenter',
      headerName: t('common:c.c.origin') as string,
      flex: 1,
      sortable: false,
    },
  ];
};

type ContractGridParams = {
  content: NotProcessContract[];
  rowSelectionModel: GridRowSelectionModel;
  setRowSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel>>;
};

function ListNotContractGrid(params: ContractGridParams) {
  const { content, rowSelectionModel, setRowSelectionModel } = params;

  const columns = useColumns();
  return (
    <DataGrid
      sx={{
        border: 'none',
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
        '& .Mui-checked': {
          color: '#ff7b00 !important',
        },
      }}
      rows={content}
      columns={columns}
      checkboxSelection
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      hideFooterPagination
    />
  );
}

export default ListNotContractGrid;
