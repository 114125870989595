import { SharingResource } from '@/app/models/summary';
import { Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { getColor, PercentageBar, StatusComponent } from '../status/status';

function useGetColumns(): GridColDef[] {
  const { t } = useTranslation(['resourceSharing', 'common']);

  return [
    {
      field: 'resource',
      headerName: t('common:collaborator') as string,
      flex: 1,
      valueFormatter: ({ value }) => value?.name,
      sortable: false,
    },
    { field: 'document', headerName: t('document') as string, width: 90, sortable: false },
    { field: 'type', headerName: t('contract') as string, width: 100, sortable: false },
    {
      field: 'originCostCenter',
      headerName: t('common:c.c.origin') as string,
      flex: 1,
      valueFormatter: ({ value }) => `${value?.code} - ${value?.name}`,
      sortable: false,
    },
    {
      field: 'destiniCostCenter',
      headerName: t('c.c.detiny') as string,
      flex: 1,
      valueFormatter: ({ value }) => `${value?.code} - ${value?.name}`,
      sortable: false,
    },
    {
      field: 'percent',
      headerName: t('percentage') as string,
      width: 170,
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <PercentageBar value={params?.value} color={getColor(params?.row?.status)} />
          <span style={{ marginLeft: '8px' }}>{`${params?.value} %`}</span>
        </div>
      ),
      sortable: false,
    },
    {
      field: 'status',
      headerName: t('common:status') as string,
      width: 120,
      renderCell: (params: GridRenderCellParams) => <StatusComponent status={params?.value} />,
      sortable: false,
    },
    { field: 'originApproverName', headerName: t('origin.approver.name') as string, flex: 1, sortable: false },
  ];
}

type RequestGridParams = {
  isSuccess: boolean;
  rowCountState: number;
  content: SharingResource[];
  pageOptions: number[];
  paginationModel: { page: number; pageSize: number };
  setPaginationModel: Dispatch<
    SetStateAction<{
      page: number;
      pageSize: number;
    }>
  >;
  rowSelectionModel: GridRowSelectionModel;
  setRowSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel>>;
  isLoading: boolean;
};

function RequestsGrid(params: RequestGridParams) {
  const { t } = useTranslation('requestsGrid');
  const columns = useGetColumns();
  const {
    isSuccess,
    rowCountState,
    content,
    pageOptions,
    paginationModel,
    setPaginationModel,
    isLoading,
    rowSelectionModel,
    setRowSelectionModel,
  } = params;

  const myCustomNoRows = () => (
    <div style={{ display: 'flex', justifyContent: 'center', height: '360px', alignItems: 'center' }}>
      <Typography>{t('no.results.found')}</Typography>
    </div>
  );

  const localizedTextsMap = {
    footerRowSelected: (count: number) =>
      count !== 1
        ? `${count.toLocaleString()} ${t('lines.selected')}`
        : `${count.toLocaleString()} ${t('line.selected')}`,
    MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) =>
        `${from} - ${to} ${t('of')} ${count}`,
    },
  };

  return (
    <div style={{ height: '100vh', minHeight: '200px', marginTop: '16px' }}>
      {isSuccess && (
        <DataGrid
          slots={{
            noRowsOverlay: myCustomNoRows,
          }}
          rowCount={rowCountState}
          disableColumnMenu
          disableColumnFilter
          disableRowSelectionOnClick
          sx={{
            border: 'none',
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .Mui-checked': {
              color: '#ff7b00 !important',
            },
          }}
          rowHeight={40}
          columnHeaderHeight={40}
          rows={content || []}
          columns={columns}
          pageSizeOptions={pageOptions}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          paginationMode="server"
          loading={isLoading}
          checkboxSelection
          localeText={localizedTextsMap}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
      )}
    </div>
  );
}

export default RequestsGrid;
