import { useTranslation } from 'react-i18next';

export enum SolicitationStatuses {
  'OPENED' = 'Pendente',
  'REJECTED' = 'Rejeitados',
  'APPROVED' = 'Aprovados',
  'all' = 'Todos',
}

export enum PeriodTabs {
  'HISTORY',
  'NOTPROCESSCONTRACT',
}

export function useSolicitationStatusTranslations() {
  const { t } = useTranslation(['common', 'requestsInput']);

  return {
    [SolicitationStatuses.OPENED]: t('pending'),
    [SolicitationStatuses.REJECTED]: t('rejected'),
    [SolicitationStatuses.APPROVED]: t('approved'),
    [SolicitationStatuses.all]: t('requestsInput:all'),
  } as const;
}
