import { PossibleStatuses } from '@/app/models/period';
import { useCurrentPeriodQuery } from '@/app/services/periodApi';
import calendar from '@/icons/calendar.svg';
import exclamation from '@/icons/exclamation.svg';
import { Card, CardContent, Chip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

function PeriodDetails() {
  const { i18n, t } = useTranslation('periodDetails');
  const { data, isSuccess } = useCurrentPeriodQuery();
  return (
    <Card sx={{ boxShadow: 3, display: 'flex', minHeight: '72px', alignItems: 'center', padding: '0 8px' }}>
      {isSuccess && (
        <CardContent sx={{ display: 'flex', maxHeight: '24px', alignItems: 'center' }}>
          <img src={calendar} alt={t('calendar.icon') as string} />
          <Typography
            sx={{ margin: '0 25px', fontSize: 14, fontWeight: 'bold', color: '#4B4B4B' }}
            color="text.secondary"
            gutterBottom
          >
            {DateTime.fromISO(data.initDate).setLocale(i18n.language).monthLong}
          </Typography>
          <div style={{ height: '24px', border: '1px solid #CCCCCC' }} />
          <Typography
            sx={{
              marginLeft: '25px',
              marginRight: '5px',
              fontSize: 14,
              fontWeight: 'bold',
              color: '#4B4B4B',
            }}
            color="text.secondary"
          >
            {`${t('opening')}:`}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {DateTime.fromISO(data.initDate).toLocaleString(DateTime.DATE_SHORT, { locale: i18n.language })}
          </Typography>
          <Typography
            sx={{
              marginLeft: '25px',
              marginRight: '5px',
              fontSize: 14,
              fontWeight: 'bold',
              color: '#4B4B4B',
            }}
            color="text.secondary"
          >
            {`${t('closing')}:`}
          </Typography>
          <Typography sx={{ fontSize: 14, whiteSpace: 'nowrap' }} color="text.secondary">
            {`${DateTime.fromISO(data.endDate, { zone: 'America/Sao_Paulo' })
              .set({ hour: parseInt(data.endTime.split(':')[0], 10) })
              .set({ minute: parseInt(data.endTime.split(':')[1], 10) })
              .set({ second: parseInt(data.endTime.split(':')[2], 10) })
              .setZone()
              .toLocaleString(DateTime.DATE_SHORT, {
                locale: i18n.language,
              })} ${t('at')} ${DateTime.fromFormat(data.endTime, 'HH:mm:ss', { zone: 'America/Sao_Paulo' })
              .setZone()
              .toFormat('HH:mm:ss')}`}
          </Typography>
          {PossibleStatuses[data.status] === PossibleStatuses.OPENED ? (
            <Chip
              sx={{ margin: '0 15px', background: '#C8F8D3', color: '#28A745', maxHeight: '24px' }}
              label={t('common:open')}
            />
          ) : (
            <>
              <Chip
                sx={{ margin: '0 15px', background: '#E4E4E4', color: '#888888', maxHeight: '24px' }}
                label={t('common:closed')}
              />
              <img src={exclamation} alt={t('exclamation.mark.icon') as string} />
              <Typography sx={{ marginLeft: '5px', fontSize: 14, whiteSpace: 'nowrap' }} color="text.secondary">
                {t('it.is.no.longer.possible.to.perform.apportionments')}
              </Typography>
            </>
          )}
        </CardContent>
      )}
    </Card>
  );
}

export default PeriodDetails;
